/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-param-reassign */
/* eslint-disable react/forbid-prop-types */

import React, { Component } from "react";
import PropTypes from "prop-types";

import moment from "moment";
import DeliverySVG from "./imgs/delivery.svg";
import PickupSVG from "./imgs/pickup.svg";
import ScheduledSVG from "./imgs/scheduled.svg";
import styles from "./OrderTrackingWidget.module.css";
import MapComponent from "../MapComponent/MapComponent";
import Button from "../Button/Button";
import Modal from "../Modal/Modal";
import { orderTrackingWidgetModes, orderTypeStrings, timeDefaults } from "../../shared/constants/order";
import { getOrderTypeString } from "../../shared/utilities/orders";
import { getTimeRangeString, normalizeDate, routeCountryPath } from "../../shared/utilities/common";

class OrderTracking extends Component {

  // eslint-disable-next-line react/sort-comp
  constructor(props) {
    super(props);
    this.state = {
      showContactStoreModal: false,
      copiedPhoneNumber: false
    };
  }

  isMobile = /Mobi|Android/i.test(navigator.userAgent);

  isScheduled = () => {
    const { order } = this.props;
    return order.deliveryTime !== "0000-00-00 00:00:00";
  }

  isScheduledForTodayOrInThePast = () => {
    const { order } = this.props;
    return moment(order.deliveryTime).isSameOrBefore(moment(), 'day');
  }

  displayAsScheduled = () => {
    return this.isScheduled() && !this.isScheduledForTodayOrInThePast();
  }

  deliveryOrPickupTime = () => {
    const { order } = this.props;

    if (order.status.status >= 5) {
      return '';
    }

    let deliveryTimeString = timeDefaults.unknown;

    if (order.deliveryStatus?.estimatedPickupTime) {
      deliveryTimeString = getTimeRangeString(order.deliveryStatus.estimatedPickupTime, order.deliveryStatus?.estimatedPickupTimeMax);
    } else if (order.deliveryStatus?.estimatedDropoffTime) {
      deliveryTimeString = getTimeRangeString(order.deliveryStatus.estimatedDropoffTime, order.deliveryStatus?.estimatedDropoffTimeMax);
    } else if (order.deliveryTime === timeDefaults.asap) {
      deliveryTimeString = timeDefaults.asapText;
    } else {
      deliveryTimeString = normalizeDate(moment(order.deliveryTime));
    }

    return deliveryTimeString === timeDefaults.invalidDate ? timeDefaults.unknown : deliveryTimeString;
  };

  onContactStoreClick = () => {
    const { order } = this.props;

    if (this.isMobile) {
      window.location.href = `tel:${encodeURIComponent(order.store.phone)}`;
    } else {
      this.setState({ copiedPhoneNumber: false, showContactStoreModal: true });
    }
  };

  onCopyPhoneNumberClick = () => {
    const { order } = this.props;

    if (navigator.clipboard) {
      navigator.clipboard.writeText(order.store.phone)
        .then(() => {
          this.setState({ copiedPhoneNumber: true });
        })
    }
  }

  onGetDirectionsClick = () => {
    const { order } = this.props;
    const isWindows = navigator.userAgent.includes('Win');

    if (isWindows) {
      // Open a Google Maps URL in a new window if the user is on a Windows machine
      window.open(`https://www.google.com/maps/dir/?api=1&destination=${order.store.lat},${order.store.lng}&query=${encodeURIComponent(order.store.address)}&travelmode=driving`);
    } else {
      // Open the default maps app on devices that support the maps:// protocol
      window.location.href = `maps://${order.store.lat},${order.store.lng}?q=${encodeURIComponent(order.store.address)}`;
    }
  };

  onViewOrderDetailsClick = () => {
    const { order } = this.props;
    window.location.href = routeCountryPath(
      `/user/order-details/${order.code}`
    );
  };

  orderStatusProgressBar = () => {
    const { order } = this.props;
    return getOrderTypeString(order) === orderTypeStrings.delivery
      ? [2, 3, 4, 6]
      : [2, 6];
  }

  orderStatus = () => {
    if (this.displayAsScheduled()) {
      return "Order scheduled";
    }

    const { order } = this.props;
    return order.deliveryStatus.status;
  }

  orderTypeIcon = () => {
    const { order } = this.props;
    if (this.displayAsScheduled()) {
      return ScheduledSVG;
    }

    return getOrderTypeString(order) === orderTypeStrings.delivery ? DeliverySVG : PickupSVG;
  }

  orderTypeProgressClass = () => {
    const { order } = this.props;
    return getOrderTypeString(order) === orderTypeStrings.delivery
      ? styles.orderTrackingDeliveryProgress
      : styles.orderTrackingPickupProgress;
  }

  orderTypeStatus = () => {
    if (this.displayAsScheduled()) {
      return this.deliveryOrPickupTime();
    }

    const { order } = this.props;
    if (getOrderTypeString(order) === orderTypeStrings.delivery) {
      if (order.status.status < 5) {
        return "Estimated arrival";
      }

      return "Order complete";
    }

    return 'Ready for pickup at';
  }

  deliveryOrPickupStatus = () => {
    const { order } = this.props;
    if (getOrderTypeString(order) === orderTypeStrings.delivery) {
      return order.status.status < 5 ? 'Delivery to' : 'Delivered to';
    }

    return order.status.status < 5 ? 'Pickup at' : 'Picked up at';
  }

  orderStatusIndex() {
    const { order } = this.props;
    const progressBar = this.orderStatusProgressBar();
    const index = progressBar.indexOf(order.status.status);

    // Orders may have a status of 5, which is not in the map but essentially
    // means the order has been delivered or picked up
    if (index === -1) {
      return progressBar.length - 1;
    }

    return index;
  }

  renderMap = () => {
    const { order } = this.props;

     const storeCoordinates = { lat: order.store?.lat, lng: order.store?.lng };
     const customerCoordinates = { lat: order.shippingAddress?.lat, lng: order.shippingAddress?.lng };
     const driverCoordinates = { lat: order.deliveryStatus?.lat, lng: order.deliveryStatus?.lon };
     const noStoreCoordinates = !storeCoordinates.lat || !storeCoordinates.lng
     const noCustomerCoordinates = !customerCoordinates.lat || !customerCoordinates.lng

     if (noStoreCoordinates || (getOrderTypeString(order) === orderTypeStrings.delivery && noCustomerCoordinates)) {
      return null;
     }

     if (getOrderTypeString(order) === orderTypeStrings.pickup) {
      return (
        <div className={styles.mapContainer}>
          <MapComponent
            showDefaultMap
            mapDefaultCoordinates={storeCoordinates}
            center={storeCoordinates}
            mode="locations"
            stores={[{ ...order.store, boundary: '[]' }]}
          />
        </div>
      )
     }

    const mode = order.status?.status >= 5 ? 'static' : 'directions';
    const bounds = new window.google.maps.LatLngBounds();
    bounds.extend(storeCoordinates);
    bounds.extend(customerCoordinates);

    return (
      <div className={styles.mapContainer}>
        <MapComponent
          storeAddress={storeCoordinates}
          customerAddress={customerCoordinates}
          driverLocation={order.status?.status === 4 ? driverCoordinates : { lat: 0, lng: 0 }}
          mode={mode}
          onMapLoad={map => {
            if (mode !== 'static') {
              map.fitBounds(bounds)
            }
            return null;
          }}
        />
      </div>
    )
  }

  renderButtons = () => {

    const {order} = this.props;

    return (
      /*
         * Buttons
         *------------------------------------------------------------------------------ */
      <div className={styles.buttonsContainer}>
        <Button
          handleSubmit={this.onViewOrderDetailsClick}
          label={(
            <span className={styles.buttonLabel}>
              <svg className={styles.onlyOnDesktop} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5.9375 8.125H14.0625" stroke="#F6F3F7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M5.9375 10.625H14.0625" stroke="#F6F3F7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M3.125 15.625V4.9375C3.125 4.78832 3.18536 4.64524 3.2928 4.53975C3.40025 4.43426 3.54597 4.375 3.69792 4.375H16.3021C16.454 4.375 16.5998 4.43426 16.7072 4.53975C16.8146 4.64524 16.875 4.78832 16.875 4.9375V15.625L14.5833 14.5L12.2917 15.625L10 14.5L7.70833 15.625L5.41667 14.5L3.125 15.625Z" stroke="#F6F3F7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              </svg>

              <span>View Order Details</span>
            </span>
            )}
          customContainerStyles={`${styles.primaryButton} viewOrderDetailsButton`}
        />

        <div className={styles.secondaryButtonsContainer}>
          <Button
            handleSubmit={this.onContactStoreClick}
            label={(
              <span className={styles.buttonLabel}>
                <span className={styles.onlyOnMobile}>Contact Store</span>
                <span className={styles.onlyOnDesktop}>{`Call ${order.store.phone}`}</span>
                <svg className={styles.onlyOnDesktop} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M13.125 13.125H16.875V3.125H6.875V6.875" stroke="#461D58" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M13.125 6.875H3.125V16.875H13.125V6.875Z" stroke="#461D58" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </span>
              )}
            customContainerStyles={`${styles.secondaryButton} ${styles.contactStoreButton}`}
          />

          {getOrderTypeString(order) === orderTypeStrings.pickup ? (
            <Button
              handleSubmit={this.onGetDirectionsClick}
              label={(
                <span className={styles.buttonLabel}>
                  <svg className={styles.onlyOnDesktop} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M16.25 8.125C16.25 13.75 10 18.125 10 18.125C10 18.125 3.75 13.75 3.75 8.125C3.75 6.4674 4.40848 4.87769 5.58058 3.70558C6.75269 2.53348 8.3424 1.875 10 1.875C11.6576 1.875 13.2473 2.53348 14.4194 3.70558C15.5915 4.87769 16.25 6.4674 16.25 8.125V8.125Z" stroke="#461D58" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M10 10.625C11.3807 10.625 12.5 9.50571 12.5 8.125C12.5 6.74429 11.3807 5.625 10 5.625C8.61929 5.625 7.5 6.74429 7.5 8.125C7.5 9.50571 8.61929 10.625 10 10.625Z" stroke="#461D58" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>

                  <span>Get Directions</span>
                </span>
                )}
              customContainerStyles={`${styles.secondaryButton} getDirectionsButton`}
            />
            ) : ''}
        </div>
      </div>
    );
  }

  renderBottomContent = () => {
    const { mode, children } = this.props;
    if (mode === orderTrackingWidgetModes.default) {
      return this.renderButtons();
    } if (mode === orderTrackingWidgetModes.orderDetails && children) {
      return children;
    }

    return null;
  }

  render() {
    const { order, chatBubble, mode } = this.props;
    const { showContactStoreModal, copiedPhoneNumber } = this.state;

    return (
      <div
        className={`
          ${styles.orderTracking}
          ${chatBubble ? styles.orderTrackingChatBubble : ''}
          p-[20px]
          rounded-[20px] border-[1px] border-lightDry dark:border-darkBorder
          bg-white dark:bg-dark
          font-filsonProRegular text-dark dark:text-white
        `}

        data-order-code={order.code}
        data-order-type={getOrderTypeString(order)}
        data-order-status={this.orderStatusIndex()}
      >

        {showContactStoreModal ? (
          <Modal
            title="Contact Store"
            description={`<div style="font-size: 1.5rem; font-weight: bold; color: #983992">${order.store.phone}</div><span style="font-size: .8rem; font-weight: normal color: #666666">${copiedPhoneNumber ? '* Copied to clipboard':'&nbsp;'}</span>`}
            exit={null}

            action1Label={(
              <div>
                <span>Copy phone number</span>
                <svg style={{display: 'inline', width: '21px', height: '21px', margin: '-5px 0 0 7px'}} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.75} stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 7.5V6.108c0-1.135.845-2.098 1.976-2.192.373-.03.748-.057 1.123-.08M15.75 18H18a2.25 2.25 0 0 0 2.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 0 0-1.123-.08M15.75 18.75v-1.875a3.375 3.375 0 0 0-3.375-3.375h-1.5a1.125 1.125 0 0 1-1.125-1.125v-1.5A3.375 3.375 0 0 0 6.375 7.5H5.25m11.9-3.664A2.251 2.251 0 0 0 15 2.25h-1.5a2.251 2.251 0 0 0-2.15 1.586m5.8 0c.065.21.1.433.1.664v.75h-6V4.5c0-.231.035-.454.1-.664M6.75 7.5H4.875c-.621 0-1.125.504-1.125 1.125v12c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V16.5a9 9 0 0 0-9-9Z" />
                </svg>
              </div>
            )}
            action1={() => this.onCopyPhoneNumberClick()}

            action2Label="Cancel"
            action2={() => this.setState({ showContactStoreModal: false })}
          />
        ) : ''}

        {/*
         * Header
         *------------------------------------------------------------------------------ */}

        <div className={styles.orderTrackingHeader}>
          <h3>
            {this.orderStatus()}
          </h3>
          <img src={this.orderTypeIcon()} alt={getOrderTypeString(order)} />
        </div>

        { (getOrderTypeString(order) === orderTypeStrings.delivery || (getOrderTypeString(order) === orderTypeStrings.pickup && order.status.status < 5)) && (
          <div className={styles.orderTrackingHeader}>
            <span>{this.orderTypeStatus()}</span>
            <span className={styles.orderTrackingEta}>
              {!this.displayAsScheduled() && this.deliveryOrPickupTime()}
            </span>
          </div>
        )}

        {/*
         * Progress indicator
         *------------------------------------------------------------------------------ */}

        <div
          className={`
          ${styles.orderTrackingProgress}
          ${this.orderTypeProgressClass()}
        `}
        >
          {this.orderStatusProgressBar().map((status, index) => {
            return (
              <div
                key={index}
                className={
                  index <= this.orderStatusIndex()
                    ? styles.orderTrackingActiveStep
                    : ""
                }
              />
            );
          })}
        </div>

        {/*
         * Delivery/Pickup map
         *------------------------------------------------------------------------------ */}

        <div className={styles.orderAddressDetails}>
          <div>
            <em>
              {this.deliveryOrPickupStatus()}
            </em>
            <strong>
              {getOrderTypeString(order) === orderTypeStrings.delivery
                ? order.shippingAddress.address1
                : order.store.name}
            </strong>
          </div>

          {/* TODO: uncomment when better estimates are available */}
          {/* {getOrderTypeString(order) === orderTypeStrings.delivery ? ( */}
          {/*  <div className={styles.orderAddressLatestArrival}> */}
          {/*    <em>Latest arrival</em> */}
          {/*    <strong>ETA</strong> */}
          {/*  </div> */}
          {/* ) : ''} */}
        </div>

        {this.renderMap()}

        {getOrderTypeString(order) === orderTypeStrings.pickup && mode === orderTrackingWidgetModes.default && (
          <p className={styles.pickupInstructions}>When you arrive go to the pick-up area and let a store manager know you&apos;re picking up an online order.</p>
        )}
        
        {this.renderBottomContent()}

      </div>
    );
  }
}

OrderTracking.propTypes = {
  order: PropTypes.object.isRequired,
  chatBubble: PropTypes.bool,
  mode: PropTypes.string,
  children: PropTypes.node,
};

OrderTracking.defaultProps = {
  chatBubble: false,
  mode: orderTrackingWidgetModes.default,
  children: null,
};

export default OrderTracking;
